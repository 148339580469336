import { useLocation } from "@remix-run/react";
import { useCallback } from "react";
import { toast } from "sonner";
import { isTierUpgrade, TierName } from "sutro-common/studio-entitlements";

import { SutroApi } from "~/lib/sutro-api";
import { either } from "~/lib/sutro-api/Api";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

import { PricingTable } from "../pricing-table/pricing-table";

const SubscriptionView = () => {
  const { currentSubscription } = useEntitlements();
  const location = useLocation();
  const api = SutroApi.getApi().authenticate();

  const changeTier = useCallback(
    async (newTier: TierName) => {
      if (newTier === currentSubscription.tier.name) {
        return;
      }
      if (newTier === "Custom") {
        return;
      }
      const upgradingTier = isTierUpgrade(
        currentSubscription.tier.name,
        newTier
      );
      await api
        .patch("/users/current/subscription", {
          type: upgradingTier ? "upgrade" : "downgrade",
          toTierName: newTier,
          force: true,
        })
        .then(
          either((err) => {
            toast.error(err.message ?? "Failed to change tier");
          }).or(() => {
            // Reload the page & remove query params
            window.location.href = location.pathname;
          })
        );
    },
    [api, currentSubscription.tier.name, location.pathname]
  );

  return (
    <div className="flex size-full flex-col">
      <PricingTable
        heading={"Developer Subscription Testing"}
        subHeading={
          <p className="text-text-muted max-w-xl text-center text-sm sm:max-w-full">
            Switch between subscription plans seamlessly. If you want to mimick
            production behavior, you can change your subscription in user
            settings or anywhere except DevTools. Use{" "}
            <a
              href="https://docs.stripe.com/testing#testing-interactively"
              target="_blank"
              rel="noopener noreferrer"
              className="text-text-info underline"
            >
              Stripe's test card
            </a>{" "}
            to test the upgrade and downgrade flows.
          </p>
        }
        changingToTier={null}
        onChangeTier={changeTier}
        showFreePlan={true}
      />
    </div>
  );
};

export { SubscriptionView };
